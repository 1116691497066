import React, { Component } from 'react';

import { Keyframes, animated } from 'react-spring/renderprops';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import './App.css';

const windowWidth = window.document.documentElement.clientWidth;

const introStart = 1500;

const AnimationOverlay = Keyframes.Spring({
  intro: {width: '0%', delay: introStart + 1000},
  none: { width: '100%', delay: 0 }
})

const Logo = Keyframes.Spring({
  intro: { height: windowWidth > 1024 ? 'calc(11vh)' : 'calc(19vw)', width: windowWidth > 1024 ? 'calc(29vh)' : 'calc(50vw)', top: windowWidth > 1024 ? 'calc(3vh)' : 'calc(5vh)', left: windowWidth > 1024 ? 'calc(5vw - 5vh)' : 'calc(5vw)', delay: introStart },
  none: { height: windowWidth > 1024 ? 'calc(50vh)' : 'calc(19vw)', width: windowWidth > 1024 ? 'calc(130vh)' : 'calc(50vw)', top: windowWidth > 1024 ? 'calc(25vh)' : 'calc(40vh)', left: windowWidth > 1024 ? 'calc(50vw - 65vh)' : 'calc(5vw)', delay: 0 }
})

const PageContent = Keyframes.Spring({
  intro: { opacity: 1, delay: introStart + 1500 },
  none: { opacity: 0, delay: introStart + 1500 }
})

const InfoContainer = Keyframes.Spring({
  open: { width: windowWidth > 1024 ? 300 : '100%', delay: 0 },
  close: { width: windowWidth > 1024 ? 0 : '0%', delay: 400 }
})

const InfoContent = Keyframes.Spring({
  open: { opacity: 1, delay: 400 },
  close: { opacity: 0, delay: 0 }
})

function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

class App extends Component {
  
  constructor(props) {
    super(props);
  
    this.state = {
      intro: false,
      open: 'none'
    }
  }

  componentDidMount = () => {
    this.setState({ intro: true });

    sleep(introStart + 1500).then(() => {
      this.setState({ open: 'us' });
    });
  }

  handleClick = (value) => {
    if (this.state !== value) {
      this.setState({ open: 'none' });

      sleep(400).then(() => {
        this.setState({ open: value });
      });
    }
  }

  handleNext = () => {
    const prevOpen = this.state.open;
    this.setState({ open: 'none' });

    sleep(400).then(() => {
      this.setState({ open: prevOpen === "us" ? "services" : prevOpen === "services" ? "clients" : prevOpen === "clients" ? "contact" : "us" });
    });
  }

  render() {
    const moment = this.state.intro ? "intro" : "none";

    const usState = this.state.open === "us" ? "open" : "close";
    const servicesState = this.state.open === "services" ? "open" : "close";
    const contactState = this.state.open === "contact" ? "open" : "close";
    const clientsState = this.state.open === "clients" ? "open" : "close";
    
    const innerHeight = window.innerHeight;

    return (
      <div className="App" style={{ height: innerHeight }}>
        <img src={require("./images/background.jpg")} alt="Fondo de pantalla" className="backgroundOverlay" />
        <div className="darkenOverlay"></div>
        <div className="gradientOverlay"></div>
        <AnimationOverlay native state={moment}>
          {({ width }) => (
            <animated.div
              className="animationOverlay"
              style={{
                width: width
              }}>
                
              </animated.div>
            )}
        </AnimationOverlay>

        <Logo native state={moment}>
          {({ height, top, left }) => (
            <animated.div
              className="logoContainer"
              style={{
                height: height, top: top, left: left
              }}>
              <img src={require("./images/logoBTL.png")} alt="Logo" className="logo" />
            </animated.div>
          )}
        </Logo>
        { windowWidth > 1024 &&
          <PageContent native state={moment}>
            {({ opacity }) => (
              <animated.div
                className="sloganContainer"
                style={{
                  opacity: opacity
                }}>
                  <h3>MARKETING</h3>
                  <h1>PROMOCIONAL</h1>
              </animated.div>
            )}
          </PageContent>
        }
        { windowWidth > 1024 &&
          <PageContent native state={moment}>
            {({ opacity }) => (
              <animated.div
                className="navbar"
                style={{
                  opacity: opacity
                }}>
                  <p onClick={() => this.handleClick("us")}>NOSOTROS</p>
                  <p onClick={() => this.handleClick("services")}>SERVICIOS</p>
                  <p onClick={() => this.handleClick("clients")}>CLIENTES</p>
                  <p onClick={() => this.handleClick("contact")}>CONTACTO</p>
              </animated.div>
            )}
          </PageContent>
        }

        <InfoContainer native state={usState}>
          {({ width }) => (
            <animated.div
              className="infoContainer usContainer"
              style={{
                width: width
              }}>
                <InfoContent native state={usState}>
                  {({ opacity }) => (
                    <animated.div
                      className="infoContent usContent"
                      style={{
                        opacity: opacity
                      }}>
                        <p style={{ pointerEvents: usState === "close" ? "none" : "all" }}>Somos una agencia dedicada al Marketing promocional ofreciendo a nuestros clientes la solución adecuada a cada caso en particular. Brindamos soluciones integrales para expositores en eventos y ferias, activación de marca, promotoras, marketing digital e imagen corporativa.</p>
                        <a style={{ pointerEvents: usState === "close" ? "none" : "all" }} href={"https://firebasestorage.googleapis.com/v0/b/enfoque-btl.appspot.com/o/Presentacion%20Enfoque%20BTL.pdf?alt=media&token=e1fcdb6c-932d-46d2-8fe2-c2693aebd825"} target="_blank" rel="noopener noreferrer">Ver Presentación Oficial</a>
                    </animated.div>
                  )}
                </InfoContent>
            </animated.div>
          )}
        </InfoContainer>

        <InfoContainer native state={servicesState}>
          {({ width }) => (
            <animated.div
              className="infoContainer servicesContainer"
              style={{
                width: width
              }}>
                <InfoContent native state={servicesState}>
                  {({ opacity }) => (
                    <animated.div
                      className="infoContent servicesContent"
                      style={{
                        opacity: opacity
                      }}>
                        <p>Activaciones de marca</p>
                        <p>Promotoras y promotores</p>
                        <p>Diseño y armado de stand</p>
                        <p>Producción gráfica</p>
                        <p>Juegos promocionales</p>
                        <p>Equipamiento técnico</p>
                        <p>Catering y alquiler de mobiliario</p>
                        <p style={{ marginBottom: 'calc(3vh)' }}>Organización de Eventos Corporativos</p>
                    </animated.div>
                  )}
                </InfoContent>
            </animated.div>
          )}
        </InfoContainer>

        <InfoContainer native state={contactState}>
          {({ width }) => (
            <animated.div
              className="infoContainer contactContainer"
              style={{
                width: width
              }}>
                <InfoContent native state={contactState}>
                  {({ opacity }) => (
                    <animated.div
                      className="infoContent contactContent"
                      style={{
                        opacity: opacity
                      }}>
                        <h3 style={{ pointerEvents: contactState === "close" ? "none" : "all" }}>Soledad Suarez</h3>
                        <p style={{ pointerEvents: contactState === "close" ? "none" : "all" }}> +54 (11) 3688-4052 </p>
                        <a style={{ pointerEvents: contactState === "close" ? "none" : "all" }} href="mailto: ssuarez@enfoquebtl.com">ssuarez@enfoquebtl.com</a>
                        <a style={{ pointerEvents: contactState === "close" ? "none" : "all" }} href="https://www.facebook.com/EnfoqueBTLagencia" target="_blank" rel="noopener noreferrer" className="contactLastLine">facebook.com/EnfoqueBTLagencia</a>
                    </animated.div>
                  )}
                </InfoContent>
            </animated.div>
          )}
        </InfoContainer>

        <InfoContainer native state={clientsState}>
          {({ width }) => (
            <animated.div
              className="infoContainer clientsContainer"
              style={{
                width: width
              }}>
                <InfoContent native state={clientsState}>
                  {({ opacity }) => (
                    <animated.div
                      className="infoContent clientsContent"
                      style={{
                        opacity: opacity
                      }}>
                        <img style={{ pointerEvents: clientsState === "close" ? "none" : "all" }} src={require("./images/columbia.png")} alt="Logo Banco Columbia" className="clientsLogo" />
                        <img style={{ pointerEvents: clientsState === "close" ? "none" : "all" }} src={require("./images/dhl.png")} alt="Logo Banco DHL" className="clientsLogo" />
                        <img style={{ pointerEvents: clientsState === "close" ? "none" : "all" }} src={require("./images/geely.png")} alt="Logo Banco Geely" className="clientsLogo" />
                        <img style={{ pointerEvents: clientsState === "close" ? "none" : "all" }} src={require("./images/ford.png")} alt="Logo Ford" className="clientsLogo" />
                        <img style={{ pointerEvents: clientsState === "close" ? "none" : "all" }} src={require("./images/skyy.png")} alt="Logo Skyy Vodka" className="clientsLogo" />
                        <img style={{ marginTop: 'calc(1vh)', marginBottom: 'calc(1vh)', pointerEvents: clientsState === "close" ? "none" : "all" }} src={require("./images/libra.png")} alt="Logo Libra Seguros" className="clientsLogo" />
                    </animated.div>
                  )}
                </InfoContent>
            </animated.div>
          )}
        </InfoContainer>
        
        { windowWidth <= 1024 &&
          <PageContent native state={moment}>
            {({ opacity }) => (
              <animated.div
                className="toggleIconContainer"
                style={{
                  opacity: opacity
                }}>
                  <FontAwesomeIcon icon={faCaretRight} className="toggleIcon" onClick={this.handleNext}/>
              </animated.div>
            )}
          </PageContent>
        }
      </div>
    );
  }
}

export default App;
